/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import SettingsIcon from "@material-ui/icons/Settings";
// import ArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
export default [
    {
        subheader: "Program Module",
        items: [
            {
                title: "Dashboard",
                href: "/dashboard",
                icon: DashboardIcon
            },
            {
                title: "Persediaan",
                href: "/persediaan",
                icon: HomeWorkIcon,
                items: [
                    {
                        title: "Master Kategori",
                        href: "/persediaan/kategori",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Master Sub Kategori",
                        href: "/persediaan/subkategori",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Master Barang",
                        href: "/persediaan/barang",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Master Lokasi",
                        href: "/persediaan/lokasi",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Barang Masuk",
                        href: "/persediaan/barangmasuk",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Barang Keluar",
                        href: "/persediaan/barangkeluar",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Mutasi Stok",
                        href: "/persediaan/mutasistok",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Stok Opname",
                        href: "/persediaan/stokopname",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Laporan Persediaan",
                        href: "/persediaan/laporanpersediaan",
                        icon: ArrowRightIcon
                    }
                ]
            },
            {
                title: "Pembelian",
                href: "/pembelian",
                icon: ShoppingCartIcon,
                items: [
                    {
                        title: "Master Tipe Supplier",
                        href: "/pembelian/tipesupplier",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Master Supplier",
                        href: "/pembelian/supplier",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Pesanan Pembelian",
                        href: "/pembelian/pesananpembelian",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Faktur Pembelian",
                        href: "/pembelian/fakturpembelian",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Retur Pembelian",
                        href: "/pembelian/returpembelian",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Outstanding PO",
                        href: "/pembelian/outstandingpo",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Monitor Penerimaan",
                        href: "/pembelian/monitorpenerimaan",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Laporan Pembelian",
                        href: "/pembelian/laporanpembelian",
                        icon: ArrowRightIcon
                    }
                ]
            },
            {
                title: "Penjualan",
                href: "/penjualan",
                icon: BusinessCenterIcon,
                items: [
                    {
                        title: "Master Tipe Customer",
                        href: "/penjualan/tipecustomer",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Master Customer",
                        href: "/penjualan/customer",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Pesanan Penjualan",
                        href: "/penjualan/pesananpenjualan",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Faktur Penjualan",
                        href: "/penjualan/fakturpenjualan",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Retur Penjualan",
                        href: "/penjualan/returpenjualan",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Outstanding SO",
                        href: "/penjualan/outstandingso",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Monitor Pengiriman",
                        href: "/penjualan/monitorpengiriman",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Laporan Penjualan",
                        href: "/penjualan/laporanpenjualan",
                        icon: ArrowRightIcon
                    }
                ]
            },
            {
                title: "Finance",
                href: "/finance",
                icon: LocalAtmIcon,
                items: [
                    {
                        title: "Master Bank",
                        href: "/finance/bank",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Mutasi Bank",
                        href: "/finance/mutasibank",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Master Beban",
                        href: "/finance/bebanbiaya",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Master Kas",
                        href: "/finance/kas",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Kas Keluar",
                        href: "/finance/kaskeluar",
                        icon: ArrowRightIcon
                    },
                    // {
                    //     title: "Pembebanan Biaya",
                    //     href: "/finance/pembebananbiaya/outstanding",
                    //     icon: ArrowRightIcon
                    // },
                    {
                        title: "Uang Muka Pembelian",
                        href: "/finance/uangmukapembelian",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Uang Muka Penjualan",
                        href: "/finance/uangmukapenjualan",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Pembayaran Hutang",
                        href: "/finance/pembayaranhutang",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Penerimaan Piutang",
                        href: "/finance/penerimaanpiutang",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Monitor Hutang",
                        href: "/finance/monitorhutang",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Monitor Piutang",
                        href: "/finance/monitorpiutang",
                        icon: ArrowRightIcon
                    }
                ]
            },
            {
                title: "Pengaturan",
                href: "/pengaturan",
                icon: SettingsIcon,
                items: [
                    {
                        title: "Master User",
                        href: "/pengaturan/user",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Setting FP",
                        href: "/pengaturan/settingfp",
                        icon: ArrowRightIcon
                    },
                    {
                        title: "Ganti Pass",
                        href: "/pengaturan/gantipass",
                        icon: ArrowRightIcon
                    }
                ]
            }
        ]
    }
];
