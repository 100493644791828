// import { useSelector } from "react-redux";
export default (modul, value) => {
    // const session = useSelector(state => state.session);
    const session = JSON.parse(localStorage.getItem("user"));
    // console.log("wowowoow", session.hak_akses);
    // return true;
    if (session) {
        if (session.hak_akses) {
            const akses = session.hak_akses.filter(
                row => row.modul.toUpperCase() === modul.toUpperCase()
            );
            if (akses[0]) {
                if (akses[0].jenis_akses < value) {
                    return false;
                }
                return true;
            }
        }
    }
    return false;
};
