import * as actionTypes from 'src/actions';

const initialState = {
  loggedIn: localStorage.getItem('loggedIn')?localStorage.getItem('loggedIn'):false,
  user: localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{
    username: '',
    token: '',
  }
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      localStorage.setItem('user',JSON.stringify(action.user));
      localStorage.setItem('loggedIn',true);
      state.loggedIn = true;
      state.user = action.user;
      return {
        ...state
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      state.loggedIn = false;
      state.user = {};
      localStorage.removeItem('user');
      localStorage.removeItem('loggedIn');
      return {
        ...state
      };
    }

    default: {
      // console.log(state);
      return state;
    }
  }
};

export default sessionReducer;
