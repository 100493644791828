import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import axios from "src/utils/axios";
import { Container, Grid } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import getAkses from "src/utils/getAkses";
import LatestProjects from './LatestProjects';
import NewProjects from './NewProjects';
import RealTime from './RealTime';
import RoiPerCustomer from './RoiPerCustomer';
import PenjualanOverview from './PenjualanOverview';
import PembelianOverview from './PembelianOverview';
import PiutangOverview from './PiutangOverview';
import HutangOverview from './HutangOverview';
import CashInOverview from './CashInOverview';
import CashOutOverview from './CashOutOverview';
import UangMukaJualOverview from './UangMukaJualOverview';
import UangMukaBeliOverview from './UangMukaBeliOverview';
import PenjualanPembelianChart from './PenjualanPembelianChart';
import CashFlowChart from './CashFlowChart';
import TeamTasks from './TeamTasks';
import TodaysMoney from './TodaysMoney';
import SystemHealth from './SystemHealth';
import PerformanceOverTime from './PerformanceOverTime';
import FinancialStats from './FinancialStats';
import { logout } from "src/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(2)
  }
}));

function DashboardDefault() {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const session = useSelector(state => state.session);
  const dispatch = useDispatch();
  useEffect(() => {
      let mounted = true;

      // console.log(authToken);
      const fetchData = () => {
          const authToken = {
              headers: { Authorization: `Bearer ${session.user.token}` }
          };
          axios
              .get("/user/dashboard", authToken)
              .then(response => {
                  console.log(response.data);
                  if (mounted) {
                      setData(response.data);

                  }
              })
              .catch(error => {
                  if (error.response.status === 401) {
                      dispatch(logout());
                  }
              });
      };

      fetchData();

      return () => {
          mounted = false;
      };
  }, [session, dispatch]);

  if(!data){
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Default Dashboard"
    >
      <Container maxWidth={false}>
        <Header />
        {getAkses("dashboard", 1) && 
        <Grid
          container
          spacing={3}
          className={classes.grid}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
          >
            <PenjualanOverview amount={data.now.penjualan} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
          >
            <PembelianOverview amount={data.now.pembelian} /> 
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
          >
            <CashInOverview amount={data.now.masuk}  />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
          >
            <CashOutOverview  amount={data.now.keluar} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
          >
            <PiutangOverview amount={data.now.piutang}  />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
          >
            <HutangOverview amount={data.now.hutang}  /> 
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
          >
            <UangMukaJualOverview  amount={data.now.umj}  />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
          >
            <UangMukaBeliOverview  amount={data.now.umb}  />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <PenjualanPembelianChart  data={{pembelian:data.chart.pembelian,penjualan:data.chart.penjualan}}/>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <CashFlowChart data={{masuk:data.chart.masuk,keluar:data.chart.keluar}}/>
          </Grid>
        </Grid>
        }
      </Container>
    </Page>
  );
}

export default DashboardDefault;
