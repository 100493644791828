/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";
import DashboardAnalyticsView from "./views/DashboardAnalytics";
import DashboardDefaultView from "./views/DashboardDefault";
import OverviewView from "./views/Overview";
import PresentationView from "./views/Presentation";

export default [
    {
        path: "/",
        exact: true,
        component: () =>
            localStorage.getItem("loggedIn") ? (
                <Redirect to="/dashboard" />
            ) : (
                <Redirect to="/auth/login" />
            )
    },
    {
        path: "/auth",
        component: AuthLayout,
        routes: [
            {
                path: "/auth/login",
                exact: true,
                component: lazy(() => import("src/views/Login"))
            },
            {
                path: "/auth/register",
                exact: true,
                component: lazy(() => import("src/views/Register"))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: "/errors",
        component: ErrorLayout,
        routes: [
            {
                path: "/errors/error-401",
                exact: true,
                component: lazy(() => import("src/views/Error401"))
            },
            {
                path: "/errors/error-404",
                exact: true,
                component: lazy(() => import("src/views/Error404"))
            },
            {
                path: "/errors/error-500",
                exact: true,
                component: lazy(() => import("src/views/Error500"))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        route: "*",
        component: DashboardLayout,
        routes: [
            {
                path: "/pengaturan/gantipass",
                exact: true,
                component: lazy(() => import("src/views/Pengaturan/GantiPass"))
            },
            {
                path: "/pengaturan/settingfp",
                exact: true,
                component: lazy(() => import("src/views/Pengaturan/SettingFP"))
            },
            {
                path: "/pengaturan/user",
                exact: true,
                component: lazy(() => import("src/views/Pengaturan/User"))
            },
            {
                path: "/pengaturan/user/:id",
                exact: true,
                component: lazy(() => import("src/views/Pengaturan/UserDetail"))
            },
            {
                path: "/pengaturan/user/:id/:tab",
                exact: true,
                component: lazy(() => import("src/views/Pengaturan/UserDetail"))
            },
            {
                path: "/finance/penerimaanpiutang",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PenerimaanPiutang")
                )
            },
            {
                path: "/finance/penerimaanpiutang/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PenerimaanPiutangCreate")
                )
            },
            {
                path: "/finance/penerimaanpiutang/create/:id/:id2",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PenerimaanPiutangCreate")
                )
            },
            {
                path: "/finance/penerimaanpiutang/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PenerimaanPiutangDetail")
                )
            },
            {
                path: "/finance/penerimaanpiutang/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PenerimaanPiutangDetail")
                )
            },
            {
                path: "/finance/pembayaranhutang",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PembayaranHutang")
                )
            },
            {
                path: "/finance/pembayaranhutang/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PembayaranHutangCreate")
                )
            },
            {
                path: "/finance/pembayaranhutang/create/:id/:id2",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PembayaranHutangCreate")
                )
            },
            {
                path: "/finance/pembayaranhutang/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PembayaranHutangDetail")
                )
            },
            {
                path: "/finance/pembayaranhutang/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PembayaranHutangDetail")
                )
            },
            {
                path: "/finance/uangmukapenjualan",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/UangMukaPenjualan")
                )
            },
            {
                path: "/finance/uangmukapenjualan/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/UangMukaPenjualanDetail")
                )
            },
            {
                path: "/finance/uangmukapenjualan/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/UangMukaPenjualanDetail")
                )
            },
            {
                path: "/finance/uangmukapembelian",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/UangMukaPembelian")
                )
            },
            {
                path: "/finance/uangmukapembelian/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/UangMukaPembelianDetail")
                )
            },
            {
                path: "/finance/uangmukapembelian/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/UangMukaPembelianDetail")
                )
            },
            {
                path: "/finance/pembebananbiaya/details/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PembebananBiayaDetail")
                )
            },
            {
                path: "/finance/pembebananbiaya/details/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PembebananBiayaDetail")
                )
            },
            {
                path: "/finance/pembebananbiaya/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/PembebananBiaya")
                )
            },
            {
                path: "/finance/bebanbiaya",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/MasterBebanBiaya")
                )
            },
            {
                path: "/finance/bebanbiaya/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/MasterBebanBiayaDetail")
                )
            },
            {
                path: "/finance/bebanbiaya/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/MasterBebanBiayaDetail")
                )
            },
            {
                path: "/finance/mutasibank",
                exact: true,
                component: lazy(() => import("src/views/Finance/MutasiBank"))
            },
            {
                path: "/finance/bank",
                exact: true,
                component: lazy(() => import("src/views/Finance/MasterBank"))
            },
            {
                path: "/finance/bank/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/MasterBankDetail")
                )
            },
            {
                path: "/finance/bank/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/MasterBankDetail")
                )
            },
            {
                path: "/finance/kas",
                exact: true,
                component: lazy(() => import("src/views/Finance/MasterKas"))
            },
            {
                path: "/finance/kas/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/MasterKasDetail")
                )
            },
            {
                path: "/finance/kas/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/MasterKasDetail")
                )
            },
            {
                path: "/finance/kaskeluar",
                exact: true,
                component: lazy(() => import("src/views/Finance/KasKeluar"))
            },
            {
                path: "/finance/kaskeluar/create",
                exact: true,
                component: lazy(() => import("src/views/Finance/KasKeluarCreate"))
            },
            {
                path: "/finance/kaskeluar/laporan/:tipe/:dari/:sampai",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/LaporanKasKeluar")
                )
            },
            {
                path: "/finance/kaskeluar/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/KasKeluarDetail")
                )
            },
            {
                path: "/finance/kaskeluar/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Finance/KasKeluarDetail")
                )
            },
            {
                path: "/finance/monitorpiutang",
                exact: true,
                component: lazy(() => import("src/views/Finance/MonitorPiutang"))
            },
            {
                path: "/finance/monitorhutang",
                exact: true,
                component: lazy(() => import("src/views/Finance/MonitorHutang"))
            },
            {
                path: "/penjualan/tipecustomer",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterTipeCustomer")
                )
            },
            {
                path: "/penjualan/tipecustomer/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterTipeCustomerDetail")
                )
            },
            {
                path: "/penjualan/tipecustomer/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterTipeCustomerDetail")
                )
            },
            {
                path: "/penjualan/customer",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterCustomer")
                )
            },
            {
                path: "/penjualan/customer/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterCustomerDetail")
                )
            },
            {
                path: "/penjualan/customer/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterCustomerDetail")
                )
            },
            {
                path: "/penjualan/pesananpenjualan",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PesananPenjualan")
                )
            },
            {
                path: "/penjualan/pesananpenjualan/preview/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PesananPenjualanPrint")
                )
            },
            {
                path: "/penjualan/pesananpenjualan/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PesananPenjualanCreate")
                )
            },
            {
                path: "/penjualan/pesananpenjualan/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PesananPenjualanDetail")
                )
            },
            {
                path: "/penjualan/pesananpenjualan/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PesananPenjualanDetail")
                )
            },
            {
                path: "/penjualan/pengirimanbarang",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PengirimanBarang")
                )
            },
            {
                path: "/penjualan/pengirimanbarang/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PengirimanBarangCreate")
                )
            },
            {
                path: "/penjualan/pengirimanbarang/preview/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PengirimanBarangPreview")
                )
            },
            {
                path: "/penjualan/pengirimanbarang/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PengirimanBarangDetail")
                )
            },
            {
                path: "/penjualan/pengirimanbarang/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PengirimanBarangDetail")
                )
            },
            {
                path: "/pembelian/tipesupplier",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterTipeSupplier")
                )
            },
            {
                path: "/pembelian/tipesupplier/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterTipeSupplierDetail")
                )
            },
            {
                path: "/pembelian/tipesupplier/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterTipeSupplierDetail")
                )
            },
            {
                path: "/pembelian/supplier",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterSupplier")
                )
            },
            {
                path: "/pembelian/supplier/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterSupplierDetail")
                )
            },
            {
                path: "/pembelian/supplier/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterSupplierDetail")
                )
            },
            {
                path: "/pembelian/pesananpembelian",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PesananPembelian")
                )
            },
            {
                path: "/pembelian/pesananpembelian/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PesananPembelianCreate")
                )
            },
            {
                path: "/pembelian/pesananpembelian/preview/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PesananPembelianPreview")
                )
            },
            {
                path: "/pembelian/pesananpembelian/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PesananPembelianDetail")
                )
            },
            {
                path: "/pembelian/pesananpembelian/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PesananPembelianDetail")
                )
            },
            {
                path: "/pembelian/penerimaanbarang",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PenerimaanBarang")
                )
            },
            {
                path: "/pembelian/penerimaanbarang/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PenerimaanBarangCreate")
                )
            },
            {
                path: "/pembelian/penerimaanbarang/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PenerimaanBarangDetail")
                )
            },
            {
                path: "/pembelian/penerimaanbarang/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PenerimaanBarangDetail")
                )
            },
            {
                path: "/penjualan/laporanpenjualan",
                exact: true,
                component: lazy(() => import("src/views/Penjualan/LaporanPenjualan2"))
            },
            {
                path: "/penjualan/laporanpenjualan/:laporan/:dari/:sampai/:tipe/:lokasi/:kode_barang/:supplier/:customer",
                exact: true,
                component: lazy(() => import("src/views/Penjualan/LaporanPenjualan2"))
            },
            {
                path: "/penjualan/fakturpenjualan",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualan")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/laporan/:tipe/:dari/:sampai",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/LaporanPenjualan")
                )
            },
            {
                path: "/penjualan/pesananpenjualan/laporan/:tipe/:dari/:sampai",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/LaporanPesanan")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualanCreate")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/preview/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualanPreview")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/previewppn/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualanSuratJalanPreview")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualanDetail")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualanDetail")
                )
            },
            {
                path: "/penjualan/returpenjualan",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/ReturPenjualan")
                )
            },
            {
                path: "/penjualan/returpenjualan/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/ReturPenjualanCreate")
                )
            },
            {
                path: "/penjualan/returpenjualan/preview/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/ReturPenjualanPreview")
                )
            },
            {
                path: "/penjualan/returpenjualan/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/ReturPenjualanDetail")
                )
            },
            {
                path: "/penjualan/returpenjualan/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/ReturPenjualanDetail")
                )
            },
            {
                path: "/pembelian/laporanpembelian",
                exact: true,
                component: lazy(() => import("src/views/Pembelian/LaporanPembelian"))
            },
            {
                path: "/pembelian/laporanpembelian/:laporan/:dari/:sampai/:tipe/:lokasi/:kode_barang/:supplier/:customer",
                exact: true,
                component: lazy(() => import("src/views/Pembelian/LaporanPembelian"))
            },
            {
                path: "/pembelian/fakturpembelian",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/FakturPembelian")
                )
            },
            {
                path: "/pembelian/fakturpembelian/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/FakturPembelianCreate")
                )
            },
            {
                path: "/pembelian/fakturpembelian/laporan/:tipe/:dari/:sampai",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/LaporanPembelian")
                )
            },
            {
                path: "/pembelian/fakturpembelian/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/FakturPembelianDetail")
                )
            },
            {
                path: "/pembelian/fakturpembelian/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/FakturPembelianDetail")
                )
            },
            {
                path: "/pembelian/returpembelian",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/ReturPembelian")
                )
            },
            {
                path: "/pembelian/returpembelian/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/ReturPembelianCreate")
                )
            },
            {
                path: "/pembelian/returpembelian/preview/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/ReturPembelianPreview")
                )
            },
            {
                path: "/pembelian/returpembelian/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/ReturPembelianDetail")
                )
            },
            {
                path: "/pembelian/returpembelian/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/ReturPembelianDetail")
                )
            },
            {
                path: "/pembelian/outstandingpo",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/OutstandingPO")
                )
            },
            {
                path: "/penjualan/outstandingso",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/OutstandingSO")
                )
            },
            {
                path: "/penjualan/monitorpengiriman",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MonitorPengiriman")
                )
            },
            {
                path: "/pembelian/monitorpenerimaan",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MonitorPenerimaan")
                )
            },
            {
                path: "/persediaan/laporanpersediaan",
                exact: true,
                component: lazy(() => import("src/views/Persediaan/LaporanPersediaan"))
            },
            {
                path: "/persediaan/laporanpersediaan/:laporan",
                exact: true,
                component: lazy(() => import("src/views/Persediaan/LaporanPersediaan"))
            },
            {
                path: "/persediaan/laporanpersediaan/:laporan/:dari/:sampai/:lokasi/:kode_barang/:supplier/:customer",
                exact: true,
                component: lazy(() => import("src/views/Persediaan/LaporanPersediaan"))
            },
            {
                path: "/persediaan/laporanpersediaan/:laporan/:dari/:sampai/:lokasi/:kode_barang/:supplier/:customer/:kategori",
                exact: true,
                component: lazy(() => import("src/views/Persediaan/LaporanPersediaan"))
            },
            {
                path: "/persediaan/kategori",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterKategori")
                )
            },
            {
                path: "/persediaan/kategori/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterKategoriDetail")
                )
            },
            {
                path: "/persediaan/kategori/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterKategoriDetail")
                )
            },
            {
                path: "/persediaan/subkategori",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterSubKategori")
                )
            },
            {
                path: "/persediaan/subkategori/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterSubKategoriDetail")
                )
            },
            {
                path: "/persediaan/subkategori/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterSubKategoriDetail")
                )
            },
            {
                path: "/persediaan/barang",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterBarang")
                )
            },
            {
                path: "/persediaan/barang/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterBarangDetail")
                )
            },
            {
                path: "/persediaan/barang/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterBarangDetail")
                )
            },
            {
                path: "/persediaan/lokasi",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterLokasi")
                )
            },
            {
                path: "/persediaan/lokasi/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterLokasiDetail")
                )
            },
            {
                path: "/persediaan/lokasi/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterLokasiDetail")
                )
            },
            {
                path: "/persediaan/barangmasuk",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangMasuk")
                )
            },
            {
                path: "/persediaan/barangmasuk/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangMasukCreate")
                )
            },
            {
                path: "/persediaan/barangmasuk/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangMasukDetail")
                )
            },
            {
                path: "/persediaan/barangmasuk/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangMasukDetail")
                )
            },
            {
                path: "/persediaan/barangkeluar",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangKeluar")
                )
            },
            {
                path: "/persediaan/barangkeluar/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangKeluarCreate")
                )
            },
            {
                path: "/persediaan/barangkeluar/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangKeluarDetail")
                )
            },
            {
                path: "/persediaan/barangkeluar/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangKeluarDetail")
                )
            },
            {
                path: "/persediaan/mutasistok",
                exact: true,
                component: lazy(() => import("src/views/Persediaan/MutasiStok"))
            },
            {
                path: "/persediaan/mutasistok/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MutasiStokCreate")
                )
            },
            {
                path: "/persediaan/mutasistok/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MutasiStokDetail")
                )
            },
            {
                path: "/persediaan/mutasistok/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MutasiStokDetail")
                )
            },
            {
                path: "/persediaan/stokopname",
                exact: true,
                component: lazy(() => import("src/views/Persediaan/StokOpname"))
            },
            {
                path: "/persediaan/stokopname/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/StokOpnameCreate")
                )
            },
            {
                path: "/persediaan/stokopname/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/StokOpnameDetail")
                )
            },
            {
                path: "/persediaan/stokopname/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/StokOpnameDetail")
                )
            },
            {
                path: "/dashboard",
                exact: true,
                component: DashboardDefaultView
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    }
];
