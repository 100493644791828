import axios from "axios";

const instance = axios.create({
    // baseURL: "http://api32.ptpas.org"
    // baseURL: "http://api.ptpas.explor.id"
    baseURL: "https://api.ptpas.org"
});
// baseURL: "http://api32.ptpas.org"
// baseURL: "https://api.ptpas.org"

export default instance;
